import React from "react";
import { graphql, StaticQuery, withPrefix } from "gatsby"
import SEO from "../../components/SEO";
import Layout from "../../layouts/index";
import BackgroundImage from 'gatsby-background-image';

import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

const Customers = props => {
  const json = props.data.allFeaturesJson.edges;
  return (
    <Layout bodyClass="page-services" path={props.location.pathname}>
      <SEO title="Customers" />
      <div className="intro-small text-center">
        <h1>Partnereink</h1>
      </div>

      <div id="solutions" className="container pt-2 pt-md-4">
        <div className="row justify-content-center">
          {json.map(edge => (
            <div
              key={edge.node.id}
              className="col-12 col-md-6 col-lg-4 mb-2"
            >
              <Link to={edge.node.path}>
                <div className="card feature">
                  {edge.node.image && (
                    <BackgroundImage fluid={edge.node.image.childImageSharp.fluid}
                      className="card-img-top w-100" alt={"feature-" + edge.node.title}
                    >
                      &nbsp;
                    </BackgroundImage>
                  )}
                  <div className="card-body">
                    <h4 className="card-title">{edge.node.title}</h4>
                    <p className="card-text">{edge.node.description}</p>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

const customersQuery = graphql`
  query {
    allFeaturesJson {
      edges {
        node {
          id
          title
          description
          image {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          path
          lang
        }
      }
    }
  }
`;

export default props => (
  <StaticQuery
    query={customersQuery}
    render={data => (
      <Customers location={props.location} props data={data} {...props} />
    )}
  />
)
